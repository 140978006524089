<template>
  <div ref="visualization" id="visualization">
  </div>
</template>

<script>
import DocumentsVisualization from '@/libraries/DocumentsVisualization.ts';

export default {
  name: 'DocumentsVisualization',
  props: {
    documentSequencer: Object,
    onlyDisplay: Number,
  },
  computed: {
    root() {
      return this.$refs.visualization;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      new DocumentsVisualization(
        this.root,
        this.documentSequencer,
        this.$store.state.configuration,
        this.$store.state.configuration,
      ).visualize();
    },
    reload() {
      let child = this.root.lastElementChild;
      while (child) {
        this.root.removeChild(child);
        child = this.root.lastElementChild;
      }
      this.load();
    },
  },
  watch: {
    documentSequencer() {
      this.reload();
    },
    '$store.state.configuration': function reloadVisualization() {
      this.reload();
    },
  },
};
</script>
