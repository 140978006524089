<template>
  <div ref="polarityVisualization" id="polarity-visualization" class="relative">
  </div>
</template>

<script>
import PolarityVisualization from '@/libraries/PolarityVisualization.js';

export default {
  name: 'PolarityVisualization',
  props: {
    documentPolarity: Array,
    mainDocumentPolarity: {
      type: [Array, null],
      default: null,
    },
  },
  data() {
    return {
      resizeTimeout: null,
      reloadNextTickDone: true,
    };
  },
  computed: {
    root() {
      return this.$refs.polarityVisualization;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.load();
      window.addEventListener('resize', this.handleResize);
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.reload();
      }, 100);
    },
    load() {
      new PolarityVisualization(
        this.root,
        this.documentPolarity,
        this.mainDocumentPolarity,
      ).visualize();
    },
    remove() {
      let child = this.root.lastElementChild;
      while (child) {
        this.root.removeChild(child);
        child = this.root.lastElementChild;
      }
    },
    reload() {
      this.remove();
      this.load();
    },
    reloadNextTick() {
      this.reloadNextTickDone = false;
      this.$nextTick(() => {
        if (!this.reloadNextTickDone) {
          this.reload();
        }
        this.reloadNextTickDone = true;
      });
    },
  },
  watch: {
    documentPolarity() {
      this.reloadNextTick();
    },
    mainDocumentPolarity() {
      this.reloadNextTick();
    },
  },
};
</script>
