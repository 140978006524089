import * as d3 from 'd3';
import POLARITIES from '@/libraries/Polarities.js';


export default class PolarityVisualization {
  /**
   * Create a new polarity visualization.
   * Arguments:
   *   root: Root element of the visualization, where elements will be appended.
   *   documentPolarity: array of 2-valued arrays (i.e. tuples) where the first
   *      element is the article description and the second is the polarity
   *      value.
   *   min: Minimal possible value.
   *   max: Maximal possible value.
   *   minLabel: Label for the min value.
   *   maxLabel: Label for the max value.
   */
  constructor(
    root,
    documentPolarity,
    mainDocumentPolarity = null,
    min = -1,
    max = 1,
    minLabel = 'Possibly contra',
    maxLabel = 'Possibly pro',
  ) {
    this.root = d3.select(root);
    this.documentPolarity = documentPolarity;
    this.mainDocumentPolarity = mainDocumentPolarity;
    this.min = min;
    this.max = max;
    this.minLabel = minLabel;
    this.maxLabel = maxLabel;
    this.textPadding = 10; // px
    this.strokeWidth = 2; // px
    this.scaleStroke = 'black';
    this.width = '100%';
    this.height = '80px';
  }

  visualize() {
    const tooltip = this.root.append('div')
      .attr('class', 'polarity-tooltip');
    const svg = this.root.append('svg')
      .attr('width', this.width)
      .attr('height', this.height);

    const svgRect = svg.node().getBoundingClientRect();

    const negRect = svg.append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('dominant-baseline', 'text-before-edge')
      .text(this.minLabel)
      .node()
      .getBBox();

    const posRect = svg.append('text')
      .attr('x', svgRect.width)
      .attr('y', 0)
      .attr('dominant-baseline', 'text-before-edge')
      .attr('text-anchor', 'end')
      .text(this.maxLabel)
      .node()
      .getBBox();

    const top = Math.floor(Math.min(negRect.y, posRect.y));
    let bottom = Math.ceil(Math.max(negRect.y + negRect.height, posRect.y + posRect.height));
    if ((bottom - top) % 2 === 0) {
      bottom += 1;
    }
    const left = Math.ceil(negRect.x + negRect.width + this.textPadding);
    const right = Math.floor(posRect.x - this.textPadding);
    const vertCenter = top + Math.floor((bottom - top) / 2);

    svg.append('line')
      .attr('stroke', this.scaleStroke)
      .attr('x1', left)
      .attr('x2', left)
      .attr('y1', top)
      .attr('y2', bottom);

    svg.append('line')
      .attr('stroke', this.scaleStroke)
      .attr('x1', right)
      .attr('x2', right)
      .attr('y1', top)
      .attr('y2', bottom);

    svg.append('line')
      .attr('stroke', this.scaleStroke)
      .attr('x1', left)
      .attr('x2', right)
      .attr('y1', vertCenter)
      .attr('y2', vertCenter);

    const radius = vertCenter - top;
    const scaleMultiplicator = ((right - radius) - (left + radius)) / (this.max - this.min);

    const calcX = d => (d[1] - this.min) * scaleMultiplicator + left + radius;

    // svg
    //   .selectAll('svg')
    //   .data(this.documentPolarity)
    //   .enter()
    //   .append('circle')
    //   .attr('cx', calcX)
    //   .attr('fill', 'white')
    //   .attr('cy', vertCenter)
    //   .attr('r', radius);

    svg
      .selectAll('svg')
      .data(this.documentPolarity)
      .enter()
      .append('circle')
      .attr('cx', calcX)
      .attr('class', d => `document-circle ${POLARITIES.fromValue(d[1]).class}`)
      .attr('cy', vertCenter)
      .attr('r', radius)
      .on('mouseenter', (d) => {
        tooltip
          .text(d[2].title)
          .style('display', 'block')
          .style('top', '25px');
        const ttLeft = calcX(d) - (tooltip.node().getBoundingClientRect().width / 2);
        tooltip.style('left', `${ttLeft}px`);
      })
      .on('mouseleave', () => {
        tooltip.style('display', 'none');
      });

    if (this.mainDocumentPolarity) {
      const x = calcX(this.mainDocumentPolarity);
      const mainDocumentClass = POLARITIES.fromValue(this.mainDocumentPolarity[1]).class;
      svg
        .append('circle')
        .attr('cx', x)
        .attr('class', d => `main-document-circle ${mainDocumentClass}`)
        .attr('fill', 'gray')
        .attr('stroke', 'black')
        .attr('stroke-width', this.strokeWidth)
        .attr('cy', vertCenter)
        .attr('r', radius - this.strokeWidth);
      svg.append('svg:defs').append('svg:marker')
        .attr('id', 'triangle')
        .attr('refX', 5)
        .attr('refY', 5)
        .attr('markerWidth', 30)
        .attr('markerHeight', 30)
        .attr('orient', 'auto')
        .append('path')
        .attr('d', 'M 0 0 10 5 0 10 0 5')
        .style('fill', 'gray');
      const offsetTop = 5;
      let arrowWidth = -15;
      let anchor = 'end';
      if ((this.mainDocumentPolarity[1] - this.min) / (this.max - this.min) < 0.5) {
        arrowWidth *= -1;
        anchor = 'start';
      }
      svg.append('path')
        .attr('d', d => `M ${x + arrowWidth} 50 Q ${x} ${50 - (bottom + offsetTop) / 3} ${x} ${bottom + offsetTop}`)
        .attr('stroke-width', 1)
        .attr('fill', 'transparent')
        .attr('stroke', 'gray')
        .attr('marker-end', 'url(#triangle)');
      svg.append('text')
        .attr('x', x + arrowWidth)
        .attr('y', 50)
        .attr('dominant-baseline', 'hanging')
        .attr('text-anchor', anchor)
        .text(this.mainDocumentPolarity[0]);
    }
  }
}
