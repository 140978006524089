import * as d3 from 'd3';
import _ from 'lodash';
import { Document, DocumentSequencer, SequenceItem } from '@/libraries/DocumentSequencer.ts';

type Selection = d3.Selection<HTMLElement, Document, null, undefined>;
type SequenceSelection = d3.Selection<HTMLElement, Document, HTMLElement, Document>;
type HoverElements = d3.Selection<HTMLSpanElement, SequenceItem, HTMLElement, Document>

const NEUTRAL = 'NEUTRAL';
const POSITIVE = 'POSITIVE';
const NEGATIVE = 'NEGATIVE';
const POSITIVE_SENTIMENT_CLASS = 'positive-sentiment';
const NEGATIVE_SENTIMENT_CLASS = 'negative-sentiment';
const NEUTRAL_SENTIMENT_CLASS = 'neutral-sentiment';
const TOOLTIP = 'tooltip';


export default class DocumentsVisualization {
  root: Selection;

  sequencer: DocumentSequencer;

  hoverElements?: HoverElements;

  configuration: {[key: string]: any};

  currentlyHovered: d3.Selection<HTMLElement, unknown, null, undefined>[] = [];

  /**
   * Create a new document visualization.
   * Arguments:
   *   root: Root element of the visualization, where elements will be appended.
   *   documentSequencer: Sequenced documents which should be visualized.
   */
  constructor(
    root: HTMLElement,
    documentSequencer: DocumentSequencer,
    configuration: {[key: string]: any},
  ) {
    this.root = d3.select(root);
    this.sequencer = documentSequencer;
    this.configuration = configuration;
  }

  /**
   * Build the visualization.
   */
  visualize() {
    const docs = Object.values(this.sequencer.documents);
    const articles = this.root
      .selectAll('article')
      .data(docs)
      .enter()
      .append('article')
      .attr('class', `highlight-mode-${this.configuration.articleHighlightMode}`);
    const that = this;

    // Build the headlines.
    // @ts-ignore
    this.buildSequence(articles.append('h2').attr('class', 'article-headline'), datum => datum.titleSequence.items);
    // Build the lead-paragraph (if it exists).
    articles.each(function createDescription(this: HTMLElement, document: Document) {
      if (document.descriptionSequence) {
        // @ts-ignore
        that.buildSequence(d3.select(this).append('p').attr('class', 'lead-paragraph'),
          // @ts-ignore
          datum => datum.descriptionSequence.items);
      }
    });

    // And build the text.
    // @ts-ignore
    this.buildSequence(articles.append('p'), datum => datum.textSequence.items);
  }

  private markHovered(item: SequenceItem) {
    for (const hovered of this.currentlyHovered) {
      hovered.classed('underline', false);
    }
    const currentlyHovered = [];
    for (const connection of item.connections) {
      const selection = d3.select(<HTMLElement> connection.element).classed('underline', true);
      currentlyHovered.push(selection);
    }
    this.currentlyHovered = currentlyHovered;
  }

  private buildSequence(
    root: SequenceSelection,
    dataFunction: d3.ValueFn<HTMLElement, Document, SequenceItem[]>,
  ) {
    const sequenceElements = root.selectAll('span')
      .data(dataFunction)
      .enter()
      .append('span')
      .each(function addElement(item) {
        item.updateElement(this);
      });
      // .on('mouseover', (item) => { this.markHovered(item); });

    if (this.configuration.articleBiasCategoriesMode === TOOLTIP) {
      sequenceElements.each(DocumentsVisualization.addTooltips);
    }

    // Insert NEUTRAL-spans and save all leaves in textElements.
    let textElements: Array<HTMLElement> = [];
    const insertNeutral = DocumentsVisualization.insertOnSentiment(
      NEUTRAL,
      NEUTRAL_SENTIMENT_CLASS,
      textElements,
    );
    sequenceElements.each(insertNeutral);

    // Insert POSITIVE-spans and save all leaves in textElements.
    let newSequenceElements = d3.selectAll(textElements);
    textElements = [];
    const insertPositive = DocumentsVisualization.insertOnSentiment(
      POSITIVE,
      POSITIVE_SENTIMENT_CLASS,
      textElements,
    );
    newSequenceElements.each(insertPositive);

    // Insert NEGATIVE-spans and save all leaves in textElements.
    newSequenceElements = d3.selectAll(textElements);
    textElements = [];
    const insertNegative = DocumentsVisualization.insertOnSentiment(
      NEGATIVE,
      NEGATIVE_SENTIMENT_CLASS,
      textElements,
    );
    newSequenceElements.each(insertNegative);

    newSequenceElements = d3.selectAll(textElements);
    // Add text to all leaves.
    newSequenceElements.html((i: any) => i.text.replace(/(?:\r\n|\r|\n) /g, '<br>'));
  }

  private static addTooltips(this: HTMLElement, item: SequenceItem) {
    if (!item.members.size) {
      return;
    }
    const biasObjects = _.flatten(
      [...item.members].map((m: any) => m.sentiment.biasCategories),
    );
    const biasTexts = new Set(biasObjects.map(b => b.category));
    if (!biasTexts.size) {
      return;
    }

    const text = [...biasTexts].join(', ');

    // text += ' -- ';
    // const x = [...item.entities].map(i => i.representative);
    // text += x.join(', ');
    // text += ' -- ';
    // const y = [...item.members].map(i => i.sentiment.sentiment);
    // text += y.join(', ');
    d3.select(this)
      .attr('data-balloon-pos', 'up')
      .attr('aria-label', text);
  }

  private static insertOnSentiment(
    sentiment: string,
    className: string,
    textElements: Array<HTMLElement>,
  ) {
    return function insert(this: HTMLElement, item: SequenceItem | any) {
      const hasSentiment = [...item.members]
        .filter(i => i.sentiment !== null)
        .map(i => i.sentiment.sentiment)
        .includes(sentiment);
      if (hasSentiment) {
        const newElement = d3.select(this).append('span').attr('class', className);
        textElements.push(<HTMLElement> newElement.node());
      } else {
        textElements.push(this);
      }
    };
  }
}
