import _ from 'lodash';

// Attributes which may be - not must be - sequences.
// const SEQUENCE_ATTRIBUTES = [
//   'title',
//   'description',
//   'text',
// ];

interface SequenceInterface {
  text: string;
  items: SequenceItem[];
}

export class SequenceItem {
  element?: HTMLElement;

  entities: Set<any> = new Set();

  members: Set<any> = new Set();

  sequence: Sequence;

  beginChar: number;

  endChar: number;

  private entityMap: Map<any, SequenceItem[]>;

  private cachedConnections: null | Set<SequenceItem> = null;

  constructor(
    sequence: Sequence,
    beginChar: number,
    endChar: number,
  ) {
    this.sequence = sequence;
    this.beginChar = beginChar;
    this.endChar = endChar;
    this.entityMap = this.sequence.document.sequencer.entityMap;
  }

  split(
    char: number,
    addEntityBefore?: any,
    addEntityAfter?: any,
    addMemberBefore?: any,
    addMemberAfter?: any,
  ) {
    const duplicate = this.duplicate();
    this.endChar = char;
    duplicate.beginChar = char;
    if (addEntityBefore) {
      this.entities.add(addEntityBefore);
    }
    if (addEntityAfter) {
      duplicate.entities.add(addEntityAfter);
    }
    if (addMemberBefore) {
      this.members.add(addMemberBefore);
    }
    if (addMemberAfter) {
      duplicate.members.add(addMemberAfter);
    }
    return [this, duplicate];
  }

  get index(): number {
    return this.sequence.items.indexOf(this);
  }

  get connections(): Set<SequenceItem> {
    return this.getConnections();
  }

  updateElement(element: HTMLElement) {
    this.element = element;
  }

  getConnections(): Set<SequenceItem> {
    if (this.cachedConnections) {
      return this.cachedConnections;
    }
    const connections: Set<SequenceItem> = new Set();
    for (const entity of this.entities) {
      const c = this.sequence.document.sequencer.entityMap.get(entity);
      if (c) {
        for (const connection of c) {
          connections.add(connection);
        }
      }
    }
    this.cachedConnections = connections;
    return connections;
  }

  private duplicate() {
    const item = new SequenceItem(this.sequence, this.beginChar, this.endChar);
    item.entities = new Set(this.entities);
    item.members = new Set(this.members);
    for (const entity of this.entities) {
      (<SequenceItem[]> this.entityMap.get(entity)).push(item);
    }
    return item;
  }

  get text(): string {
    return this.sequence.text.slice(this.beginChar, this.endChar);
  }
}

export class Sequence implements SequenceInterface {
  text: string;

  items: SequenceItem[];

  document: Document;

  private entitySplits: number = 0;

  private entityAdditions: number = 0;

  constructor(text: string, document: Document) {
    this.document = document;
    this.text = text;
    this.items = [new SequenceItem(this, 0, text.length)];
  }

  associate(beginChar: number, endChar: number, entity: any = null, member: any = null) {
    return this.associateMultiple(
      beginChar,
      endChar,
      entity ? [entity] : [],
      member ? [member] : [],
    );
  }

  associateMultiple(beginChar: number, endChar: number, entities: any[] = [], members: any[] = []) {
    // Item which starts before beginChar but contains
    // (at least partially) text which should also be associated to the entities and members.
    const containsStart = this.items.find(
      item => item.beginChar < beginChar && item.endChar > beginChar,
    );
    if (containsStart) {
      const [before, after] = containsStart.split(beginChar);
      this.items.splice(before.index + 1, 0, after);
      if (after.entities.size > 0) {
        this.entitySplits += 1;
      }
    }

    // Item which ends after endChar but contains
    // (at least partially) text which should also be associated to entities and members.
    const containsEnd = this.items.find(
      item => item.endChar > endChar && item.beginChar < endChar,
    );
    if (containsEnd) {
      const [before, after] = containsEnd.split(endChar);
      this.items.splice(before.index + 1, 0, after);
      if (before.entities.size > 0) {
        this.entitySplits += 1;
      }
    }

    // All items which contain parts of the [beginChar-endChar] range.
    // As we splitted at beginning and ending; This also contains the
    // newly created items.
    const contained = this.items.filter(
      item => item.beginChar >= beginChar && item.endChar <= endChar,
    );

    // Add entities and members to items which are contained.
    for (const containedItem of contained) {
      if (containedItem.entities.size > 0) {
        this.entityAdditions += 1;
      }
      for (const entity of entities) {
        containedItem.entities.add(entity);
      }
      for (const member of members) {
        containedItem.members.add(member);
      }
    }
    return contained;
  }

  * elements(): any {
    for (const item of this.items) {
      yield item.element;
    }
  }

  issueSplitAndAdditionWarning() {
    if (this.entitySplits > 0) {
      console.warn('Spans of entities were splitted indicating overlapping sequences. '
                   + `Number of splitted entities: ${this.entitySplits}.`);
    }
    if (this.entityAdditions > 0) {
      console.warn('There are spans which contain multiple entities. '
                   + `Number of such spans: ${this.entityAdditions}.`);
    }
  }
}

export type DocumentsType = { [key: string]: Document };

export class Document {
  sequencer: DocumentSequencer;

  document: any;

  offsets: { [key: string]: number; } = {};

  titleSequence: Sequence;

  descriptionSequence?: Sequence;

  textSequence: Sequence;

  constructor(document: any, sequencer: DocumentSequencer) {
    this.document = document;
    this.sequencer = sequencer;

    this.setOffsets();
    this.titleSequence = new Sequence(this.document.title, this);
    if (this.document.description) {
      this.descriptionSequence = new Sequence(this.document.description, this);
    }
    this.textSequence = new Sequence(this.document.text, this);

    this.generateSequences();
    this.titleSequence.issueSplitAndAdditionWarning();
    if (this.descriptionSequence) {
      this.descriptionSequence.issueSplitAndAdditionWarning();
    }
    this.textSequence.issueSplitAndAdditionWarning();
  }

  private generateSequences() {
    for (const entity of this.sequencer.documentSet.entities) {
      for (const member of entity.members) {
        if (member.document.index === this.document.index) {
          const [
            realBeginChar,
            realEndChar,
            sequence,
          ] = this.getSequenceFromCharRange(member.headBeginChar, member.headEndChar);
          const items = sequence
            .associate(realBeginChar, realEndChar, entity, member);

          const siblings = this.sequencer.entityMap.get(entity);
          if (siblings) {
            siblings.push(...items);
          } else {
            this.sequencer.entityMap.set(entity, [...items]);
          }
        }
      }
    }
  }

  private getSequenceFromCharRange(beginChar: number, endChar: number): [number, number, Sequence] {
    if (beginChar >= this.offsets.text) {
      return [
        beginChar - this.offsets.text,
        endChar - this.offsets.text,
        this.textSequence,
      ];
    }
    if (this.descriptionSequence && beginChar >= this.offsets.description) {
      return [
        beginChar - this.offsets.description,
        endChar - this.offsets.description,
        this.descriptionSequence,
      ];
    }
    return [
      beginChar - this.offsets.title,
      endChar - this.offsets.title,
      this.titleSequence,
    ];
  }

  private setOffsets() {
    for (const { offset, attribute } of this.document.fulltextOffsets) {
      this.offsets[attribute] = offset;
    }
  }
}

export class DocumentSequencer {
  documentSet: any;

  documents: DocumentsType = {};

  entityMap: Map<any, SequenceItem[]> = new Map();

  constructor(
    documentSet: any,
    indices: number[] | undefined,
  ) {
    this.documentSet = documentSet;
    for (const entity of documentSet.entities) {
      this.entityMap.set(entity, []);
    }
    for (const document of documentSet.documents) {
      const { index } = document;
      if (indices !== undefined && !indices.includes(index)) {
        continue;
      }
      this.documents[index] = new Document(document, this);
    }

    setTimeout(() => {
      this.generateCache();
    });
  }

  private generateCache() {
    const sequenceItems = new Set();
    this.entityMap.forEach((items) => {
      for (const item of items) {
        sequenceItems.add(item);
      }
    });
    for (const item of sequenceItems) {
      (<SequenceItem> item).getConnections();
    }
  }
}
