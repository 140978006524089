<template>
  <div class="topic relative" v-if="this.documentSet !== null">
    <h1
      class="mb-2 instruction"
      v-if="(displayDocument && $store.state.configuration.articleShowPoliticalSide) ||
      $store.state.configuration.articleShowPolarityContext ||
      $store.state.configuration.articleShowPolarity"
    >Information about the article</h1>

    <!-- start: POLITICAL_ORIENTATION Box -->
    <div
      class="information-box"
      v-if="displayDocument && $store.state.configuration.articleShowPoliticalSide"
    >
      <div class="article-bias">
        The article's news outlet has the following <strong>political orientation</strong>:
        <div class="biases">
          <div
            class="bias"
            :class="{'active':
              displayDocument.politicalBias.side === POLITICAL_ORIENTATIONS.LEFT.key}"
          >{{ POLITICAL_ORIENTATIONS.LEFT.text }}</div>
          <div
            class="bias"
            :class="{'active':
              displayDocument.politicalBias.side === POLITICAL_ORIENTATIONS.MIDDLE.key}"
          >{{ POLITICAL_ORIENTATIONS.MIDDLE.text }}</div>
          <div
            class="bias"
            :class="{'active':
              displayDocument.politicalBias.side === POLITICAL_ORIENTATIONS.RIGHT.key}"
          >{{ POLITICAL_ORIENTATIONS.RIGHT.text }} </div>
        </div>
      </div>
    </div>
    <!-- end: POLITICAL_ORIENTATION Box -->

    <!-- start: ALL ARTICLE POLARITY VISUALIZATION -->
    <div
      v-if="$store.state.configuration.articleShowPolarityContext"
      class="information-box">
      <div
        class="text-center pt-4 pb-6">
        How articles that report on the topic portray (determined automatically)
        the topic's main person
        <strong>{{ documentSet.mainEntity.shortRepresentative }}</strong>:
      </div>
      <PolarityVisualization
        :documentPolarity="documentPolarity"
        :mainDocumentPolarity="mainDocumentPolarity" />
    </div>
    <!-- end: ALL ARTICLE POLARITY VISUALIZATION -->

    <!-- start: MFA POLARITY -->
    <div class="information-box" v-if="$store.state.configuration.articleShowPolarity">
      <div class="polarity-box">
        Overall, this article is regarding the topic's most frequently mentioned person
        <strong>{{ documentSet.mainEntity.shortRepresentative }}</strong> possibly:
        <div class="polarities">
          <div
            class="polarity negative"
            :class="{'active': POLARITIES.isNegative(mainDocumentPolarity[1])}"
          >contra</div>
          <div
            class="polarity neutral"
            :class="{'active': POLARITIES.isNeutral(mainDocumentPolarity[1])}"
          >ambivalent</div>
          <div
            class="polarity positive"
            :class="{'active': POLARITIES.isPositive(mainDocumentPolarity[1])}"
          >pro</div>
        </div>
      </div>
    </div>
    <!-- end: MFA POLARITY -->

    <!-- start: ARTICLES WITH DIFFERENT PERSPECTIVES -->
    <div
      class="information-box py-3 text-center"
      v-if="$store.state.configuration.articleOpposingHeadlines !== 'off'">
      While this article
        <span v-if="$store.state.configuration.articleOpposingHeadlines === 'polarity'">
          is<Tags :showPolarity="true" :document="this.displayDocument" />
          regarding <strong>{{ documentSet.mainEntity.shortRepresentative }}</strong>
        </span>
        <span v-else-if="$store.state.configuration.articleOpposingHeadlines === 'political_side'">
          has a political orientation towards the<!--
          --><Tags :showPoliticalOrientation="true" :document="this.displayDocument" />
        </span>
        <span v-else-if="$store.state.configuration.articleOpposingHeadlines === 'cluster'">
          is in<Tags :showCluster="true" :document="this.displayDocument" />
        </span>
        <span v-else>
          shows <strong>one perspective</strong>
        </span>,
      <!-- <Tags
        :showPoliticalOrientation="
        $store.state.configuration.articleOpposingHeadlines === 'political_orientation'"
        :showPolarity="
        $store.state.configuration.articleOpposingHeadlines === 'polarity'"
        :showCluster="
        $store.state.configuration.articleOpposingHeadlines === 'cluster'"
        :showAnonymous="!noAnonymousTag &&
          $store.state.configuration.overviewShowAnonymousTags"
        :document="document"
       /> -->

      following {{ opposingDocuments.length > 1 ? 'articles' : 'article' }}
      might offer a <strong>different perspective</strong>:
      <!--  / opinion / polarity / political orientation / cluster -->
      <HeadlineList class="text-left"
        :displayDocuments="opposingDocuments"
        :polarityTags="$store.state.configuration.articleOpposingHeadlines === 'polarity'"
        :politicalSideTags="$store.state.configuration.articleOpposingHeadlines
          === 'political_side'"
        :clusterTags="$store.state.configuration.articleOpposingHeadlines === 'cluster'"
        :anonymousTags="false"
        :file="$route.params.file"
      />
    </div>
    <!-- end: ARTICLES WITH DIFFERENT PERSPECTIVES -->


    <!-- start: ARTICLE -->
    <h1 class="instruction">Article</h1>
    <!-- start: HIGHLIGHT INSTRUCTIONS -->
    <div
      v-if="['positive_and_negative_as_neutral', '2-colors', '3-colors']
        .includes($store.state.configuration.articleHighlightMode)"
      class="instruction-box">
      <span
        v-if="$store.state.configuration.articleHighlightMode
          === 'positive_and_negative_as_neutral'"
      >
        <span class="bg-gray-300">Gray marks</span>
        indicate that a sentence possibly has a stance toward the highlighted person
        (<span class="bg-gray-300">pro</span> or
        <span class="bg-gray-300">contra</span>).
      </span>
      <span
        v-if="$store.state.configuration.articleHighlightMode
          === '2-colors'"
      >
        Color coding indicates a sentence's possible stance toward the highlighted person
        (<span class="bg-green-300">pro (green)</span>
        or
        <span class="bg-red-300">contra (red)</span>).
      </span>
      <span
        v-if="$store.state.configuration.articleHighlightMode
          === '3-colors'"
      >Color coding indicates a sentence's possible stance toward the highlighted person
        (<span class="bg-green-300">pro (green)</span>,
        <span class="bg-yellow-300">ambivalent (yellow)</span>, or
        <span class="bg-red-300">contra (red)</span>).</span>
    </div>
    <!-- end: HIGHLIGHT INSTRUCTIONS -->

    <!-- start: ARTICLE CONTENT -->
    <DocumentsVisualization
      v-if="documents"
      :document-sequencer="documents"
      />
    <!-- end: ARTICLE CONTENT -->
    <!-- end: ARTICLE -->

    <!-- start: FURTHER ARTICLES -->
    <template v-if="!$store.getters.mturkEnabled">
      <h2 class="further-articles">Further articles</h2>
      <InstructionBox
        :explainPoliticalSideTags="$store.state.configuration.articleShowPoliticalSideTags"
        :explainPolarityTags="$store.state.configuration.articleShowPolarityTags"
        :explainAnonymousTags="$store.state.configuration.articleShowAnonymousTags"
        :mainEntityRepresentative="documentSet.mainEntity.shortRepresentative" />
      <HeadlineList
        :documentSet="documentSet"
        :displayDocuments="documentSet.documents.filter(d => d.index !== articleIndex)"
        :identifier="this.$route.params.identifier"
        leadParagraphs="off"
        :polarityTags="$store.state.configuration.articleShowPolarityTags"
        :politicalSideTags="$store.state.configuration.articleShowPoliticalSideTags"
        :clusterTags="$store.state.configuration.articleShowClusterTags"
        :anonymousTags="$store.state.configuration.articleShowAnonymousTags"
        :max="6"
        :file="$route.params.file" />
    </template>
    <!-- end: FURTHER ARTICLES -->
    <div
      class="center-content loading-info mb-3"
      v-show="$store.state.loading && $store.getters.mturkEnabled">
      <div data-loader="circle-side"></div> Loading... Stay tuned...
    </div>
    <router-link
      v-if="nextRoute && $store.getters.mturkEnabled && !$apollo.loading && !isPopup"
      :to="nextRoute"
      class="button survey-next mt-2 force-enable-link"
      >Continue: Click here when you finished reading.</router-link>
    <button
      @click="readingAlert"
      v-else-if="$store.getters.mturkEnabled && !$apollo.loading && !isPopup"
      class="button survey-next mt-2 force-enable-link"
      >Continue: Click here when you finished reading.</button>
    <button
      @click="closePopup()"
      v-else-if="$store.getters.mturkEnabled && !$apollo.loading && isPopup"
      class="button survey-next mt-2 force-enable-link"
      >Close popup.</button>
  </div>
</template>

<script>
import { DocumentSequencer } from '@/libraries/DocumentSequencer.ts';
import DocumentsVisualization from '@/components/DocumentsVisualization.vue';
import PolarityVisualization from '@/components/PolarityVisualization.vue';
import DocumentParser from '@/libraries/DocumentParser.js';
import HeadlineList from '@/components/overview/HeadlineList.vue';
import InstructionBox from '@/components/overview/InstructionBox.vue';
import POLARITIES from '@/libraries/Polarities.js';
import POLITICAL_ORIENTATIONS from '@/libraries/PoliticalOrientations.js';
import Tags from '@/components/Tags.vue';
import topicLoader from '@/libraries/TopicLoader';

const topic = {
  name: 'show-article',
  components: {
    DocumentsVisualization,
    PolarityVisualization,
    InstructionBox,
    HeadlineList,
    Tags,
  },
  beforeMount() {
    topicLoader(this.$route.params.file).then(({ response }) => {
      this.documentSet = DocumentParser.parseDocumentSet(
        response.documentSets[0].documentSet,
        this.$store.state.configuration,
      );
    }).catch((e) => { alert(`Could not load topic: ${e}`); });
  },
  data() {
    const isPopup = this.$route.query.popup === '1';
    return {
      POLARITIES,
      POLITICAL_ORIENTATIONS,
      forceArticleIndex: false,
      nextRoute: false,
      documentSetLoaded: false,
      documentSet: null,
      skipToIteration: false,
      isPopup,
    };
  },
  methods: {
    closePopup() {
      window.close();
    },
  },
  computed: {
    showDescription() {
      return process.env.VUE_APP_SHOW_NEWS_ARTICLE_DESCRIPTION === 'true' && !this.isPopup;
    },
    documentPolarity() {
      const docs = this.documentSet.documents.filter(d => d.index !== this.articleIndex);
      return docs.map(d => ['other article', d.mainEntityPolarityValue, d]);
    },
    mainDocumentPolarity() {
      return ['this article', this.displayDocument.mainEntityPolarityValue,
        this.displayDocument];
    },
    articleIndex() {
      if (this.forceArticleIndex !== false) {
        return this.forceArticleIndex;
      }
      if (!this.$route.params.articleIndex) {
        return undefined;
      }
      return parseInt(this.$route.params.articleIndex, 10);
    },
    displayDocument() {
      if (this.articleIndex !== undefined) {
        return this.documentSet.documents[this.articleIndex];
      }
      return undefined;
    },
    opposingHeadlines() {
      return this.$store.state.configuration.articleOpposingHeadlines;
    },
    opposingDocuments() {
      if (!this.displayDocument || this.opposingHeadlines === 'off') {
        return [];
      }
      let getType;
      if (this.opposingHeadlines.includes('polarity')) {
        getType = d => d.mainEntityPolarity.key;
      } else if (this.opposingHeadlines.includes('cluster')) {
        getType = d => d.polarityClusterData.name;
      } else if (this.opposingHeadlines.includes('political_side')) {
        getType = d => d.politicalBias.side;
      }
      const displayType = getType(this.displayDocument);
      return this.documentSet.documents
        .filter(d => getType(d) !== displayType)
        .sort((x, y) => y.representativeness - x.representativeness).slice(0, 2);
    },
    mainData() {
      if (!this.displayDocument) {
        return {
          polarity: '',
          politicalBias: '',
          biasCategories: [],
        };
      }
      const polarity = this.averageMainEntityPolarity([this.displayDocument]);
      return {
        polarity: POLARITIES.fromValue(polarity).text,
        politicalBias: POLITICAL_ORIENTATIONS[this.displayDocument.politicalBias.side].text,
        biasCategories: this.topCategories(2, [this.displayDocument]),
      };
    },
    restDocuments() {
      const articles = this.documentSet.documents.filter(x => x.index !== this.articleIndex);
      return articles.sort((x, y) => y.representativeness - x.representativeness).slice(0, 2);
    },
    documents() {
      if (this.articleIndex !== undefined) {
        return new DocumentSequencer(this.documentSet, [this.articleIndex]);
      }
      return new DocumentSequencer(this.documentSet);
    },
    showPolarity() {
      return this.$store.state.configuration.articleShowPolarity;
    },
    politicalBias() {
      return this.$store.state.configuration.articleShowPoliticalSide;
    },
    showTopCategories() {
      return this.$store.state.configuration.articleShowTopBiasCategories;
    },
    labels() {
      if (!this.showPolarity && !this.politicalBias) {
        return [];
      }
      const labels = [];
      for (const doc of this.restDocuments) {
        const docLabels = [];
        if (this.politicalBias) {
          docLabels.push([
            POLITICAL_ORIENTATIONS[doc.politicalBias.side].text,
            POLITICAL_ORIENTATIONS[doc.politicalBias.side].labelClass,
          ]);
        }
        if (this.showPolarity) {
          const polarity = doc.mainEntityPolarity;
          docLabels.push([polarity.text, polarity.labelClass]);
        }
        labels.push(docLabels);
      }
      return labels;
    },
  },
};
export default topic;
</script>
